import p1_img from './product_5.jpg'
import p2_img from './product_6.jpg'
import p3_img from './product_7.jpg'
import p4_img from './product_9.jpg'

let data_product = [
  {
    id:5,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:p1_img,
    new_price:50.00,
    old_price:80.50,
  },
  {id:6,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:p2_img,
    new_price:85.00,
    old_price:120.50,
  },
  {id:7,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:p3_img,
    new_price:60.00,
    old_price:100.50,
  },
  {id:9,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:p4_img,
    new_price:100.00,
    old_price:150.00,
  },
];

export default data_product;
